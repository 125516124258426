import { render, staticRenderFns } from "./DocumentImageElement.vue?vue&type=template&id=474e3596&scoped=true&"
import script from "./DocumentImageElement.vue?vue&type=script&lang=js&"
export * from "./DocumentImageElement.vue?vue&type=script&lang=js&"
import style0 from "./DocumentImageElement.vue?vue&type=style&index=0&id=474e3596&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474e3596",
  null
  
)

export default component.exports